<div class="detail-view-container">
  <geaui-page-header class="detail-view-header" [isLoading]="loading" [pageTitle]="name" (backArrowClicked)="navigateToList()">
    <div class="header-content-wrapper">
      <geaui-button-v2 class="copy-link-button" [loading]="loading" (click)="copyLink()" type="secondary" preIcon="16px_link">{{
        'DOCUMENT.DETAIL-VIEW.COPY-LINK' | translate
      }}</geaui-button-v2>
      <geaui-button-v2 class="download-button" [loading]="loading" (click)="download()" preIcon="16px_move-layer-down">{{
        'GENERAL.DOWNLOAD' | translate
      }}</geaui-button-v2>
      <geaui-button-v2
        class="delete-button"
        *ngIf="checkUserPermission()"
        [loading]="loading"
        (click)="delete()"
        type="tertiary"
        preIcon="16px_remove"
        >{{ 'DOCUMENT.BUTTON.DELETE' | translate }}</geaui-button-v2
      >
    </div>
  </geaui-page-header>
  <pdf-viewer
    class="pdf-viewer geaui-grid geaui-sidebar-grid"
    *ngIf="!loading"
    [src]="blobUrl"
    [render-text]="false"
    [original-size]="false"></pdf-viewer>
  <ngx-skeleton-loader class="loader" *ngIf="loading" [theme]="{ height: '100%', width: '793px' }" count="1" appearance="line">
  </ngx-skeleton-loader>
</div>
