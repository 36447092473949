<div class="document-home-content">
  <div class="document-table-header">
    <geaui-attachments-v2
      class="attachments"
      [hasPermission]="checkUserPermission()"
      [maxFileSize]="attachmentsService.maxFileSizeInBytes"
      [creatingUser]="'DOCUMENT-LIST.FILE-UPLOADED-GEA' | translate"
      [isBlobStorageUpload]="true"
      [showAttachmentView]="showUploadSideModal"
      [targetDirectory]="currentPath"
      [loading]="loading"
      [showCloseIcon]="showCloseIcon"
      [subHeaderOpen]="subHeaderOpen"
      [isFileRowLoading]="isFileRowLoading"
      (closeAttachmentView)="toggleUploadSideModal()"
      (saveFilesClicked)="uploadFiles($event)"
      (maxFileSizeExceeded)="maxFileSizeExceeded()">
    </geaui-attachments-v2>
    <div class="geaui-grid geaui-sidebar-grid grid-container">
      <div class="geaui-col-desktop-md-8 geaui-col-desktop-lg-8 geaui-col-desktop-xl-20 document-breadcrumb">
        <geaui-breadcrumb
          class="breadcrumb"
          *ngIf="breadcrumbItems && breadcrumbItems.length > 0"
          [items]="breadcrumbItems"
          [showBreadcrumbBookmarkIcon]="showBreadcrumbBookmarkIcon"
          (breadcrumbClick)="onBreadcrumbClick($event)"></geaui-breadcrumb>
      </div>
      <div
        class="search-bar-upload-button-container geaui-col-desktop-md-4 geaui-col-desktop-lg-4 geaui-col-desktop-xl-4 document-searchbar">
        <geaui-search-bar-v2
          class="search-bar"
          [initialValue]="filterTableSettings.searchValue ?? ''"
          [hasSuggestions]="false"
          [placeholder]="'X.LABEL.SEARCH' | translate"
          (valueChanged)="filterTableSettings.searchValue = $event"
          (searchButtonClicked)="search()"
          (keydown)="onSearchByClickEnter($event)"
          (clearEvent)="clearSearch()"></geaui-search-bar-v2>
        <geaui-button-v2
          class="create-button"
          *ngIf="checkUserPermission()"
          [type]="'primary'"
          [preIcon]="'16px_e-add'"
          (click)="toggleUploadMenu()">
          <span>{{ 'DOCUMENT-LIST.NEW-FOLDER-UPLOAD-BUTTON' | translate }}</span>
        </geaui-button-v2>
        <div class="menu-container" *ngIf="showUploadMenu">
          <div class="menu-item" (click)="openFolderCreationPopup()">
            <geaui-icon-v2 [file]="'16px_folder-add'" [color]="'black'"></geaui-icon-v2
            ><span>{{ 'DOCUMENT-LIST.NEW-FOLDER-MENU-ITEM' | translate }}</span>
          </div>
          <geaui-complex-dialog-v2></geaui-complex-dialog-v2>
          <div class="menu-item" (click)="toggleUploadSideModal()">
            <geaui-icon-v2 [file]="'16px_upload'" [color]="'black'"></geaui-icon-v2
            ><span>{{ 'DOCUMENT-LIST.UPLOAD-FILES-MENU-ITEM' | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="document-table-content" (pointermove)="resizing($event)" (pointerup)="isResizing = false">
    <div class="sidebar" [style.min-width]="resizeXValue + 'px'" [style.width]="resizeXValue + 'px'">
      <div class="sidebar-inner">
        <div class="bookmarks" (click)="onBookmarksClicked()">
          <geaui-icon-v2
            class="folder-icon"
            [color]="bookmarkSelected ? 'blue' : 'black'"
            file="16px_saved-items"></geaui-icon-v2>
          <span class="bookmark-label" [ngClass]="{ selected: bookmarkSelected }">Bookmarks</span>
        </div>
        <p-tree
          class="file-tree"
          *ngIf="!treeLoading"
          [(selection)]="selectedNode"
          [value]="fileTree"
          (selectionChange)="onSidebarItemClicked($event)"
          selectionMode="single">
          <ng-template let-node pTemplate="default">
            <div class="tree-node" [ngClass]="{ selected: node === selectedNode }">
              <geaui-icon-v2
                class="toggle-icon"
                *ngIf="node.children && node.children.length"
                [file]="node.expanded ? '16px_chevron-down' : '16px_chevron-right'"
                [color]="node === selectedNode ? 'blue' : 'black'"
                (click)="node.expanded = !node.expanded; $event.stopPropagation()"></geaui-icon-v2>
              <div class="toggle-icon-filler" *ngIf="!node.children"></div>
              <div class="folder-name">
                <geaui-icon-v2
                  class="folder-icon"
                  [file]="node.expanded ? '16px_open-folder' : '16px_folder'"
                  [color]="node === selectedNode ? 'blue' : 'black'"
                  (click)="node.expanded = !node.expanded"></geaui-icon-v2>
                <span
                  class="node-label"
                  [ngClass]="{ selected: node === selectedNode }"
                  (dblclick)="node.expanded = !node.expanded; $event.stopPropagation()"
                  >{{ node.label }}</span
                >
              </div>
            </div>
          </ng-template>
          <ng-template let-node pTemplate="empty">
            <div class="tree-empty-message">{{ 'DOCUMENT.TREE.NO-ENTRIES-FOUND' | translate }}</div>
          </ng-template>
          <ng-template let-node pTemplate="orga">
            <div
              class="tree-node"
              [ngClass]="{ selected: node === selectedNode, 'no-children': !node.children || !node.children.length }">
              <geaui-icon-v2
                class="toggle-icon"
                *ngIf="node.children && node.children.length"
                [file]="node.expanded ? '16px_chevron-down' : '16px_chevron-right'"
                [color]="node === selectedNode ? 'blue' : 'black'"
                (click)="node.expanded = !node.expanded; $event.stopPropagation()"></geaui-icon-v2>
              <span
                class="node-label"
                [ngClass]="{ selected: node === selectedNode }"
                (dblclick)="node.expanded = !node.expanded; $event.stopPropagation()"
                >{{ node.label }}</span
              >
            </div>
          </ng-template>
        </p-tree>
        <div class="loading-tree" *ngIf="treeLoading">
          <ngx-skeleton-loader
            [theme]="{ height: '25px', width: '100%', 'margin-bottom': '8px' }"
            count="5"
            appearance="line"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="resize" (pointerdown)="resizeStart($event)">
      <div class="resize-inner" (pointerdown)="resizeStart($event)"></div>
    </div>
    <div class="document-table">
      <geaui-table-v2
        [loading]="loading"
        [columnDefinitions]="tableColumns"
        [data]="tableData"
        [rowsClickable]="true"
        [sortable]="true"
        [useSpecialClickableLogic]="true"
        [allowedFileEndings]="['pdf']"
        [totalRecords]="totalRecords"
        [defaultSettings]="defaultTableSettings"
        [nonClickableColumns]="['bookmarked', 'contextMenu']"
        (rowClicked)="onRowClicked($event)"
        tableId="document-app-table"></geaui-table-v2>
    </div>
  </div>
</div>
