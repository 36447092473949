import { AfterViewChecked, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { MenuItem, TreeNode } from 'primeng/api';
import { filter, Subscription, take, from } from 'rxjs';
import { concatMap, map, toArray } from 'rxjs/operators';
import {
  ColumnDefinition,
  ColumnRendererComponent,
  ContextMenuComponent,
  FileInfoRendererComponent,
  FileSizeRendererComponent,
  FilterTableSettings,
  HeaderRendererComponent,
  SortDirection,
  TableServiceV2,
  TranslationRendererComponent,
  UserFileV2,
  VisibilitySettingsRendererComponent,
  ApiErrorResponse,
  ErrorHandlerV2Service,
  ComplexDialogV2Service,
  SnackbarService,
  DialogV2Service,
  MetadataValueRendererComponent,
} from '@gea/digital-ui-lib';

import { BookmarkRendererComponent } from './column-renderer/bookmark-renderer/bookmark-renderer.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DirectoryContent, HierarchyElement, Permission } from '../api/v1';
import { DocumentService } from './services/document.service';
import { Location } from '@angular/common';
import { AttachmentsService } from './services/attachments.service';
import { NewFolderPopupContentComponent } from './new-folder-popup-content/new-folder-popup-content.component';
import { PermissionsService } from './services/permissions.service';
import { UserPermission } from './models/permissions.model';

@Component({
  selector: 'advance-document-app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewChecked, OnDestroy {
  breadcrumbItems: MenuItem[] = [];
  tableData: HierarchyElement[] = [];
  loading = true;
  treeLoading = true;
  currentPath = '';
  newFolder = '';
  subscriptions: Subscription[] = [];
  routerSubscription: Subscription | null = null;
  filterTableSettings: FilterTableSettings = { columns: {} };
  fileTree: TreeNode[] = [];
  totalRecords = 0;
  userPermissions: UserPermission[] = [];
  showBreadcrumbBookmarkIcon = false;
  showUploadSideModal = false;
  showUploadMenu = false;
  showCloseIcon = true;
  subHeaderOpen = false;
  isFileRowLoading = false;

  protected readonly SortDirection = SortDirection;

  columnsForSettings: ColumnDefinition[] = [
    {
      displayName: 'DOCUMENT-LIST.NAME',
      key: 'name',
      renderer: {
        component: FileInfoRendererComponent as ColumnRendererComponent<HierarchyElement>,
      },
    },
    {
      displayName: 'DOCUMENT-LIST.TYPE',
      key: 'fileEnding',
      renderer: {
        component: TranslationRendererComponent as ColumnRendererComponent<HierarchyElement>,
      },
    },
    {
      displayName: 'DOCUMENT-LIST.STATUS',
      key: 'status',
    },
    {
      displayName: 'DOCUMENT-LIST.SIZE',
      key: 'size',
    },
    {
      displayName: 'DOCUMENT-LIST.LAST-UPDATE',
      key: 'lastUpdated',
    },
    {
      displayName: 'DOCUMENT-LIST.UPLOADED-BY',
      key: 'uploadedBy',
    },
  ];

  tableColumns: ColumnDefinition[] = [
    {
      displayName: 'DOCUMENT-LIST.NAME',
      key: 'name',
      renderer: {
        component: FileInfoRendererComponent as ColumnRendererComponent<HierarchyElement>,
      },
    },
    {
      displayName: 'DOCUMENT-LIST.TYPE',
      key: 'fileEnding',
      renderer: {
        component: TranslationRendererComponent as ColumnRendererComponent<HierarchyElement>,
      },
    },
    {
      displayName: 'DOCUMENT-LIST.STATUS',
      key: 'status',
    },
    {
      displayName: 'DOCUMENT-LIST.SIZE',
      key: 'size',
      renderer: {
        component: FileSizeRendererComponent as ColumnRendererComponent<HierarchyElement>,
      },
    },
    {
      displayName: 'DOCUMENT-LIST.LAST-UPDATE',
      key: 'metadata',
      renderer: {
        component: MetadataValueRendererComponent as ColumnRendererComponent<HierarchyElement>,
        config: {
          metadataKey: 'lastModified',
          format: 'short',
        },
      },
    },
    {
      displayName: 'DOCUMENT-LIST.UPLOADED-BY',
      key: 'metadata',
      renderer: {
        component: MetadataValueRendererComponent as ColumnRendererComponent<HierarchyElement>,
        config: {
          metadataKey: 'uploadedBy',
        },
      },
    },
    {
      displayName: '',
      key: 'bookmarked',
      width: 50,
      resizeable: false,
      renderer: {
        component: BookmarkRendererComponent as ColumnRendererComponent<HierarchyElement>,
      },
    },
    {
      displayName: '',
      key: 'contextMenu',
      width: 50,
      resizeable: false,
      sortable: false,
      frozen: 'right',
      renderer: {
        component: ContextMenuComponent as ColumnRendererComponent<HierarchyElement>,
        config: {
          items: [
            {
              icon: '16px_move-layer-down',
              text: 'GENERAL.DOWNLOAD',
              action: 'downloadFile',
              disabledFor: { columnKey: 'fileEnding', columnValue: 'directory' },
            },
          ],
        },
      },
      headerRenderer: {
        component: VisibilitySettingsRendererComponent as HeaderRendererComponent<unknown>,
        config: {
          columns: this.columnsForSettings,
        },
      },
    },
  ];

  defaultTableSettings = {
    columns: {
      name: {
        sort: SortDirection.ASCENDING,
      },
    },
  };

  isResizing = false;
  resizeStartValue = 0;
  resizeXValue = 295;
  bookmarkSelected = false;
  selectedNode: TreeNode | TreeNode[] | null = null;
  showSidePanel = true;

  constructor(
    private documentService: DocumentService,
    private tableService: TableServiceV2,
    public attachmentsService: AttachmentsService,
    public permissionsService: PermissionsService,
    private complexDialogV2Service: ComplexDialogV2Service,
    private dialogService: DialogV2Service,
    private el: ElementRef<HTMLElement>,
    private errorhandlerService: ErrorHandlerV2Service,
    private renderer: Renderer2,
    private translateService: TranslateService,
    public snackbarService: SnackbarService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() {
    this.fillSearchThroughQueryParams();
    this.fetchTreeView();
    this.subscriptions.push(
      this.tableService.actions
        .pipe(
          filter(
            (action: { tableId: string; action: string; rowData: HierarchyElement }) => action.tableId === 'document-app-table'
          )
        )
        .subscribe((action) => {
          if (action.action === 'downloadFile') {
            this.documentService.downloadBinary(action.rowData.path).subscribe({
              next: (file) => this.saveFile(file, action.rowData.name),
            });
          }
          if (action.action === 'deleteFileOrDirectory') {
            this.dialogService.open({
              title: 'DOCUMENT.DELETE.CONFIRMATION.TITLE',
              message: this.translateService.instant('DOCUMENT.DELETE.CONFIRMATION.MESSAGE', {
                fileOrDirectory: action.rowData.name,
              }) as string,
              yes: 'DOCUMENT.DELETE.CONFIRMATION.REMOVE',
              no: 'DOCUMENT-LIST.POPUP.CANCEL',
              closable: true,
              hideButtons: false,
              showRejectButton: true,
              buttonTypeYes: 'cancel-red',
              confirmCallback: this.deleteFileOrDirectory.bind(this, action.rowData.path, action.rowData.name),
            });
          }
        })
    );
    this.subscriptions.push(
      this.tableService.getFilterTableSettings('document-app-table').subscribe((settings: FilterTableSettings) => {
        this.filterTableSettings = settings;
        if (!this.bookmarkSelected && !this.currentPath.length) {
          return;
        }
        if (this.bookmarkSelected) {
          this.fetchBookmarks(settings);
        } else {
          this.fetchDirectory(this.currentPath, settings);
        }
      })
    );
    this.calcColumWidth();
    this.handleNavigation();
  }

  updateDynamicContextMenuItems() {
    const dynamicItems = [];
    dynamicItems.push({
      icon: '16px_move-layer-down',
      text: 'GENERAL.DOWNLOAD',
      action: 'downloadFile',
      disabledFor: { columnKey: 'fileEnding', columnValue: 'directory' },
    });

    if (this.checkUserPermission()) {
      dynamicItems.push({
        icon: '16px_remove',
        text: 'DOCUMENT.BUTTON.DELETE',
        action: 'deleteFileOrDirectory',
      });
    }

    const contextMenuColumn = this.tableColumns.find((column) => column.key === 'contextMenu');
    if (contextMenuColumn && contextMenuColumn.renderer && contextMenuColumn.renderer.config) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      contextMenuColumn.renderer.config.items = dynamicItems;
    }
  }

  fillSearchThroughQueryParams() {
    this.route.queryParams.subscribe((params) => {
      if (params['search']) {
        this.filterTableSettings.searchValue = params['search'] as string;
        this.search();
      }
    });
  }

  resizeStart(e: PointerEvent) {
    this.isResizing = true;
    this.resizeStartValue = e.x;
    e.stopPropagation();
  }

  resizing(e: PointerEvent) {
    if (this.isResizing) {
      this.resizeXValue = this.resizeXValue + (e.x - this.resizeStartValue);
      this.resizeStartValue = e.x;
    }
    e.stopPropagation();
  }

  goToDetailPage(element: HierarchyElement) {
    if (element.resourceType == HierarchyElement.ResourceTypeEnum.Directory) return;
    if (element.fileEnding == 'pdf') void this.router.navigate(['/detail-view', element.path]);
  }

  search() {
    this.tableService.updateFilterTableSettings('document-app-table', {
      searchValue: this.filterTableSettings?.searchValue,
      search: true,
      columns: {},
    });
  }

  onSearchByClickEnter(event: KeyboardEvent) {
    if (event.code !== 'Enter') return;
    this.search();
  }

  clearSearch() {
    this.filterTableSettings.searchValue = '';
    this.search();
  }

  ngAfterViewChecked() {
    const spanElements: NodeListOf<HTMLElement> = this.el.nativeElement.querySelectorAll('tr td:nth-child(2) span');

    spanElements.forEach((span: Element) => {
      if (span.textContent && span.textContent.trim() != this.translateService.instant('DOCUMENT.DIRECTORY')) {
        const parentTr = span.closest('tr');
        this.renderer.setStyle(parentTr, 'cursor', 'default');
      }
    });
  }

  onRowClicked(element: HierarchyElement) {
    if (element.resourceType == HierarchyElement.ResourceTypeEnum.Directory) {
      this.bookmarkSelected = false;
      const selectedNode = this.findSelectedNode(element.path + element.name, this.fileTree);
      if (selectedNode) {
        this.selectedNode = selectedNode;
      }
      this.currentPath = element.path;
      void this.router.navigateByUrl(this.currentPath);
      this.clearSearchValue();
    } else this.goToDetailPage(element);
  }

  onBreadcrumbClick(menuItem: MenuItem) {
    if (this.bookmarkSelected) return;
    if (menuItem?.items?.[0]?.label) {
      const selectedNode = this.findSelectedNode(menuItem.items[0].label, this.fileTree);
      if (selectedNode) {
        this.selectedNode = selectedNode;
      }
      this.currentPath = menuItem.items[0].label;
      void this.router.navigateByUrl(this.currentPath);
      this.clearSearchValue();
    }
  }

  onBookmarksClicked() {
    this.bookmarkSelected = true;
    this.selectedNode = null;
    this.fetchBookmarks();
  }

  onSidebarItemClicked(node: TreeNode | TreeNode[] | null) {
    const selectedNode = node as TreeNode;
    this.bookmarkSelected = false;
    this.currentPath = selectedNode.key ?? '';
    void this.router.navigateByUrl(this.currentPath);
    this.clearSearchValue();
  }

  showDirectoryContent(directoryContent: DirectoryContent) {
    this.totalRecords = directoryContent.totalRecords;
    directoryContent.content.forEach((element) => {
      if (element.name === element.organizationId) {
        element.name = element.organizationName ?? element.organizationId;
      }
    });
    const pathEntries: string[] = directoryContent.path.split('/');
    let currentPath = '';
    if (!this.bookmarkSelected) {
      this.showBreadcrumbBookmarkIcon = false;
      this.breadcrumbItems = pathEntries.map((entry) => {
        if (currentPath != '') {
          currentPath += '/';
        }
        currentPath += entry;
        return { label: entry, items: [{ label: currentPath }] };
      });
      const foundOrg = directoryContent.content.find((element) => element.organizationId === this.breadcrumbItems[0].label);
      if (foundOrg) {
        this.breadcrumbItems[0].label = foundOrg.organizationName ?? foundOrg.organizationId;
      } else if (!this.breadcrumbItems[0].label) {
        this.breadcrumbItems = this.breadcrumbItems.slice(1);
      }
    } else {
      this.breadcrumbItems = [{ label: 'Bookmarks', items: [{ label: 'Bookmarks' }] }];
      this.showBreadcrumbBookmarkIcon = true;
    }
    this.tableData = directoryContent.content;
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.routerSubscription?.unsubscribe();
  }

  updateContextMenuConfig() {
    const contextMenuColumn = this.tableColumns.find(
      (column) => column.renderer && column.renderer.component === ContextMenuComponent
    );

    if (contextMenuColumn?.renderer?.config) {
      // Update the disabledFor columnValue with the new translation
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
      contextMenuColumn.renderer.config.items.forEach((item: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (item.disabledFor && item.disabledFor.columnKey === 'fileEnding') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          item.disabledFor.columnValue = 'DOCUMENT.DIRECTORY';
        }
      });
      // Re-assign the tableColumns property to trigger Angular change detection
      this.tableColumns = [...this.tableColumns];
    }
  }

  openFolderCreationPopup() {
    // eslint-disable-next-line  @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    this.complexDialogV2Service.open(
      {
        title: 'DOCUMENT-LIST.POPUP.NEW-FOLDER',
        yes: 'DOCUMENT-LIST.POPUP.ADD',
        no: 'DOCUMENT-LIST.POPUP.CANCEL',
        closable: true,
        hideButtons: false,
        showRejectButton: true,
        confirmCallback: (data: unknown) => {
          this.createNewFolder(data as string);
        },
      },
      NewFolderPopupContentComponent
    );
  }

  createNewFolder(folderName: string) {
    this.attachmentsService.createFolder(this.currentPath, folderName).subscribe({
      next: () => {
        this.fetchTreeView();
        this.snackbarService.add({
          summary: 'DOCUMENT-LIST.FOLDER-CREATE.SUCCESS.TITLE',
          detail: this.translateService.instant('DOCUMENT-LIST.FOLDER-CREATE.SUCCESS.MESSAGE', {
            folderName: `'${folderName}'`,
          }) as string,
          severity: 'success',
        });
      },
      error: () => {
        this.snackbarService.add({
          summary: 'DOCUMENT-LIST.FOLDER-CREATE.FAILURE.TITLE',
          detail: this.translateService.instant('DOCUMENT-LIST.FOLDER-CREATE.FAILURE.MESSAGE', {
            folderName: `'${folderName}'`,
          }) as string,
          severity: 'error',
        });
      },
    });
  }

  toggleUploadSideModal() {
    this.showUploadSideModal = !this.showUploadSideModal;
    this.showUploadMenu = false;
  }

  toggleUploadMenu() {
    this.showUploadMenu = !this.showUploadMenu;
  }

  uploadFiles(attachments: UserFileV2[]) {
    this.loading = true;
    this.isFileRowLoading = true;
    this.showCloseIcon = false;
    const files = attachments.map((attachment) => attachment.file).filter((file) => file !== undefined);
    this.attachmentsService.addAttachment(files, this.currentPath).subscribe({
      next: () => {
        this.isFileRowLoading = false;
        this.fetchTreeView();
        this.loading = false;
        this.showCloseIcon = true;
        this.snackbarService.add({
          summary: 'DOCUMENT-LIST.FILE-UPLOAD.SUCCESS.TITLE',
          detail: this.translateService.instant('DOCUMENT-LIST.FILE-UPLOAD.SUCCESS.MESSAGE', {
            folderName: this.getTargetDirectoryFromPath(this.currentPath),
          }) as string,
          severity: 'success',
        });
      },
      error: (err: ApiErrorResponse) => {
        this.isFileRowLoading = false;
        this.showCloseIcon = true;
        this.handleError(err);
      },
    });
  }

  maxFileSizeExceeded() {
    this.snackbarService.add({
      summary: 'DOCUMENT-LIST.MAX-FILE-SIZE-EXCEEDED.TITLE',
      detail: this.translateService.instant('DOCUMENT-LIST.MAX-FILE-SIZE-EXCEEDED.MESSAGE', {
        maxFileSize: this.convertBytesToMB(this.attachmentsService.maxFileSizeInBytes),
      }) as string,
      severity: 'error',
    });
  }

  private fetchDirectory(path: string, settings?: FilterTableSettings) {
    this.loading = true;

    this.documentService
      .showDirectory(path.startsWith('/') ? path.slice(1) : path, settings)
      .pipe(take(1))
      .subscribe({
        next: this.showDirectoryContent.bind(this),
        error: () => (this.loading = false),
      });
  }

  private fetchBookmarks(settings?: FilterTableSettings) {
    this.loading = true;
    this.documentService
      .getBookmarks(settings)
      .pipe(take(1))
      .subscribe({
        next: this.showDirectoryContent.bind(this),
        error: () => (this.loading = false),
      });
  }

  private fetchTreeView() {
    this.documentService
      .getTreeView()
      .pipe(take(1))
      .subscribe({
        next: (treeData) => {
          this.fileTree = treeData;
          if (treeData.length === 0) {
            this.treeLoading = false;
            this.loading = false;
            return;
          }
          this.currentPath = this.selectedNodeFromPath() ?? '';
          this.selectedNode = { key: this.currentPath };
          this.fetchDirectory(this.currentPath, this.filterTableSettings);
          this.treeLoading = false;
          this.setUserPermissions();
        },
        error: () => {
          this.treeLoading = false;
          this.loading = false;
        },
      });
  }

  private setUserPermissions() {
    this.loading = true;
    const ids: string[] = this.fileTree.filter((node) => node.key).map((node) => node.key as string);

    from(ids)
      .pipe(
        concatMap((id) =>
          this.permissionsService.getPermissions(id).pipe(
            map((permissions) => {
              return { organization: id, permissions: permissions };
            })
          )
        ),
        toArray()
      )
      .subscribe((userPermissionsArray) => {
        this.userPermissions = userPermissionsArray;
        this.loading = false;
        this.updateDynamicContextMenuItems();
      });
  }

  checkUserPermission() {
    const currentOrga = this.currentPath.split('/')[0];

    const userPermission = this.userPermissions.find((up) => up.organization === currentOrga);

    if (userPermission) {
      return userPermission.permissions.permissions.includes(Permission.WriteDocuments);
    }

    return false;
  }

  private handleNavigation() {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && !this.treeLoading) {
        if (event.url.startsWith('/')) {
          event.url = event.url.slice(1);
        }
        // const url = decodeURIComponent(event.url);
        this.currentPath = event.url;
        const selectedNode = this.findSelectedNode(event.url, this.fileTree);
        if (selectedNode && selectedNode !== this.selectedNode) {
          this.selectedNode = selectedNode;
          this.fetchDirectory(event.url, this.filterTableSettings);
        }
      }
    });
  }

  private findSelectedNode(key: string, nodes: TreeNode[]): TreeNode | void {
    for (const node of nodes) {
      if (node.key === key) return node;
      if (node.children) {
        const result = this.findSelectedNode(key, node.children);
        if (result) return result;
      }
    }
  }

  private saveFile(file: Blob, fileName: string) {
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(file);
    a.href = objectUrl;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(objectUrl);
  }

  private calcColumWidth() {
    const content: HTMLElement | null = this.el.nativeElement.querySelector('.document-home-content');
    if (!content) return; // exit if content is null or undefined
    const contentWidth = content.offsetWidth;
    const padding = 259;
    const columnWidth = (contentWidth - padding) / 6;
    this.tableColumns = [
      ...this.tableColumns.map((column, index) =>
        index < 6
          ? {
              ...column,
              width: columnWidth,
            }
          : column
      ),
    ];
  }

  deleteFileOrDirectory(path: string, name: string) {
    const fullPath = decodeURIComponent(path + name);
    this.documentService.delete(fullPath).subscribe({
      next: () => {
        this.fetchTreeView();
        this.snackbarService.add({
          summary: this.translateService.instant('DOCUMENT.DELETE.SUCCESS.TITLE', {
            fileOrDirectory: name,
          }) as string,
          detail: this.translateService.instant('DOCUMENT.DELETE.SUCCESS.MESSAGE', {
            fileOrDirectory: name,
          }) as string,
          severity: 'success',
        });
      },
      error: (err: ApiErrorResponse) => {
        this.handleError(err);
      },
    });
  }

  private clearSearchValue() {
    this.tableService.updateFilterTableSettings('document-app-table', {
      searchValue: '',
      search: false,
      page: 0,
      columns: {},
    });
  }

  private selectedNodeFromPath(): string {
    let path = this.location.path();
    if (path === '') {
      return this.fileTree[0].key ?? '';
    }
    if (path.startsWith('/')) {
      path = path.slice(1);
    }
    return decodeURIComponent(path);
  }

  convertBytesToMB(bytes: number): number {
    return bytes / 1024 / 1024;
  }

  private handleError(error: ApiErrorResponse) {
    this.errorhandlerService.handleError(error);
    this.loading = false;
  }

  getTargetDirectoryFromPath(path: string): string {
    let parts = path.split('/');
    parts = parts.filter((part) => part !== '');
    return parts[parts.length - 1];
  }
}
