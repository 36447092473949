import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { InjectionToken, NgModule } from '@angular/core';
import { HttpClient, provideHttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import {
  ActionRequiredState,
  AppEnvironmentState,
  AttachmentsV2Module,
  BreadcrumbV2Module,
  ButtonV2Module,
  ComplexDialogV2Module,
  DialogV2Module,
  GeaMissingTranslationHandler,
  GeaTranslateHttpLoader,
  GeaUiLibConfiguration,
  HeaderModule,
  HeaderConfig,
  IconModule,
  InputV2Module,
  PageHeaderModule,
  PermissionsState,
  SearchBarV2Module,
  SideModalV2Module,
  TableV2Module,
  UserState,
  PageLayoutModule,
  SidebarV2Module,
} from '@gea/digital-ui-lib';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { NgxsModule } from '@ngxs/store';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TreeModule } from 'primeng/tree';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DetailViewComponent } from './home/detail-view/detail-view.component';
import { NewFolderPopupContentComponent } from './home/new-folder-popup-content/new-folder-popup-content.component';
import { HomeComponent } from './home/home.component';
import { BookmarkRendererComponent } from './home/column-renderer/bookmark-renderer/bookmark-renderer.component';
import { ApiModule, Configuration, DocumentApiService } from './api/v1';
import { DocumentService } from './home/services/document.service';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../environments/models/environment.model';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

const translationFactory = (http: HttpClient, environment: EnvironmentConfiguration) => {
  return new GeaTranslateHttpLoader(http, environment.localizationStorageURL, ['i18n/1/document/']);
};

export const HEADER_CONFIG = new InjectionToken<HeaderConfig>('HeaderConfig');

@NgModule({
  declarations: [AppComponent, HomeComponent, BookmarkRendererComponent, DetailViewComponent, NewFolderPopupContentComponent],
  imports: [
    NgxsModule.forRoot([UserState, ActionRequiredState, AppEnvironmentState, PermissionsState], {
      compatibility: {
        strictContentSecurityPolicy: true,
      },
    }),
    AppRoutingModule,
    ApiModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    IconModule,
    TableV2Module,
    BreadcrumbV2Module,
    SearchBarV2Module,
    InputV2Module,
    TreeModule,
    PageLayoutModule,
    SidebarV2Module,
    PageHeaderModule,
    PdfViewerModule,
    ButtonV2Module,
    DialogV2Module,
    NgxSkeletonLoaderModule,
    TranslateModule.forRoot({
      extend: true,
      defaultLanguage: 'en-US',
      useDefaultLang: false,
      loader: {
        deps: [HttpClient, ENVIRONMENT_CONFIG],
        provide: TranslateLoader,
        useFactory: translationFactory,
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: GeaMissingTranslationHandler },
    }),
    HeaderModule.forRoot(HEADER_CONFIG),
    SideModalV2Module,
    AttachmentsV2Module,
    ComplexDialogV2Module,
    ProgressSpinnerModule,
  ],
  providers: [
    DocumentService,
    DocumentApiService,
    TranslatePipe,
    provideHttpClient(),
    {
      provide: 'baseUrl',
      useFactory: (env: EnvironmentConfiguration) => env.portal.baseURL ?? env.baseURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'subscriptionKey',
      useValue: '',
    },
    {
      provide: 'storageURL',
      useFactory: (env: EnvironmentConfiguration) => env.localizationStorageURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'iconPath',
      useFactory: (env: EnvironmentConfiguration) => env.iconStorageURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: 'appContentPath',
      useFactory: (env: EnvironmentConfiguration) => env.appContentStorageURL,
      deps: [ENVIRONMENT_CONFIG],
    },
    {
      provide: Configuration,
      useFactory: (env: EnvironmentConfiguration) =>
        new Configuration({
          basePath: env.documents.baseURL ?? env.baseURL + 'document-app/v1',
        }),
      deps: [ENVIRONMENT_CONFIG],
      multi: false,
    },
    {
      provide: GeaUiLibConfiguration,
      useFactory: (env: EnvironmentConfiguration) =>
        new GeaUiLibConfiguration({
          basePath: (env.portal.baseURL ?? env.baseURL) + 'geaid/v1',
        }),
      deps: [ENVIRONMENT_CONFIG],
      multi: false,
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
